import {createTypes} from 'redux-action-types';

export const types = createTypes('Order.', 'OPEN_MODAL', 'CLOSE_MODAL');

export const openModal = ({businessId, serviceId, masterId}) => dispatch => dispatch({  type: types.OPEN_MODAL,
  payload: {
    businessId,
    serviceId,
    masterId
  }
})

export const closeModal = () => dispatch => dispatch({type: types.CLOSE_MODAL});
