import {asyncAction, createTypes} from 'redux-action-types';
import message from 'antd/lib/message';


import {ajax} from '../../api/ajax';
import {
  FETCH_STATION_PROJECTS,
  FETCH_STATION_USER,
  FETCH_STATION_USER_ORDERS,
  URL_ORDER_CANCEL,
  URL_SEND_ORDER
} from '../../api/routes';

export const types = createTypes(
    'Applications-info.',
    asyncAction('FETCH_STATION_USER_ORDERS'),
    asyncAction('FETCH_STATION_INFO'),
    asyncAction('URL_SEND_ORDER'),
    asyncAction('ORDER_CANCEL'),
    'ADD_NEW_ORDER',
    'SAVE_SEARCH_DATA',
    asyncAction('GET_PROJECTS'),
    asyncAction('GET_NEXT_PROJECTS')
);

const countProjectOnPage = 9;

export const getNextProjects = (id) => (dispatch, getState) => {

  const {page} = getState().ordersInfo.projects;

  dispatch({type: types.GET_NEXT_PROJECTS_REQUEST});

  return ajax({
    url: FETCH_STATION_PROJECTS(id) + `?page_size=${countProjectOnPage}&page_number=${page + 1}`,
    cb: res => dispatch({
      type: types.GET_NEXT_PROJECTS_SUCCESS,
      payload: res
    }),
    err: err => dispatch({
      type: types.GET_NEXT_PROJECTS_FAIL,
      payload: err
    })
  });
};

export const getProjects = (id) => (dispatch, getState) => {

  const {page} = getState().ordersInfo.projects;

  dispatch({type: types.GET_PROJECTS_REQUEST});

  return ajax({
    url: FETCH_STATION_PROJECTS(id) + `?page_size=${countProjectOnPage}&page_number=${page}`,
    cb: res => dispatch({
      type: types.GET_PROJECTS_SUCCESS,
      payload: res
    }),
    err: err => dispatch({
      type: types.GET_PROJECTS_FAIL,
      payload: err
    })
  });
};

// Get all applications
export const getApplications = stationId => (dispatch) => {

  dispatch({type: types.FETCH_STATION_USER_ORDERS_REQUEST});

  return ajax({
    url: FETCH_STATION_USER_ORDERS(stationId),
    cb: res => dispatch({
      type: types.FETCH_STATION_USER_ORDERS_SUCCESS,
      payload: {
        orders: res.data
      }
    })
  });
};

export const getStationInfo = id => (dispatch, getState) => {

  const {
    locate: {language}
  } = getState();

  dispatch({type: types.FETCH_STATION_INFO_REQUEST});

  return ajax({
    url: FETCH_STATION_USER(id),
    cb: res => dispatch({
      type: types.FETCH_STATION_INFO_SUCCESS,
      payload: {
        ...res.data
      }
    }),
    err: (res, r) => dispatch({
      type: types.FETCH_STATION_INFO_FAIL,
      payload: {status: r.response.status}
    }),
    language: language === 'uk' ? 'UA' : language.toUpperCase()
  });
};

// Send new application to server
export const createNewApplication = payload => (dispatch) => {

  dispatch({type: types.URL_SEND_ORDER_REQUEST});

  return ajax({
    url: URL_SEND_ORDER,
    method: 'POST',
    data: payload,
    cb: res => res.data.id,
    err: err => {
      err.forEach(error => {
        message.error(error.detail);
      });
      return dispatch({
        type: types.URL_SEND_ORDER_FAIL,
        payload: err
      });
    }
  });
};

// Send new application to server
export const cancelApplication = ordersId => (dispatch) => {

  dispatch({type: types.ORDER_CANCEL_REQUEST});

  return ajax({
    url: URL_ORDER_CANCEL(ordersId),
    method: 'PUT',
    cb: res => dispatch({
      type: types.ORDER_CANCEL_SUCCESS
    }),
    err: err => dispatch({
      type: types.ORDER_CANCEL_FAIL,
      payload: err
    })
  });
};


// Creating new application (just for redux)
export const createNewApplicationAction = () => ({
  type: types.ADD_NEW_ORDER
});

// Writing search data to store
export const saveSearchData = payload => ({
  type: types.SAVE_SEARCH_DATA,
  payload
});
