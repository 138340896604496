import {asyncAction, createTypes} from 'redux-action-types';
import querystring from 'querystring';
import isEmpty from 'lodash/isEmpty';
import {VALID_GEO_TYPES} from '../../constants/constants';
import {ajax} from '../../api/ajax';
import {
    URL_GET_LOCATION_BY_IP,
    URL_GET_LOCATION_SLUG,
    URL_GET_STYLES,
    URL_SEARCH_BRANDS_TERMS,
    URL_SEARCH_TERMS
} from '../../api/routes';

export const types = createTypes(
    'SearchFields.',
    'SET_ALL_FIELDS',

    'SET_MAIN',
    'SET_MASTER',
    'SET_BRAND',
    'SET_PLACE',
    'SET_PLACE_ACTIVE',
    'SET_ZOOM',
    'SET_DATE',
    'SET_TIME',

    'SET_REMOTE_INSURANCE',
    'SET_REVIEW',

    'CLEAR_ALL_TABS',

    'SAVE_FIELDS',

    'SET_LOCATION_SLUG',
    'SET_LOCATION_ID',
    'SET_LOCATION_NAME',
    asyncAction('GET_LOCATION_SLUG'),
    asyncAction('GET_LOCATION_BY_IP'),

    'CLEAR_ALL_TERMS',

    'SET_CAR_MODELS',
    asyncAction('GET_CAR_MODELS'),

    'CLEAR_BRAND',

    // Action types for Hallway
    'SET_QUADRATURE',
    'SET_PRICE',
    'ADD_STYLES',
    'REMOVE_STYLES',
    'SET_ACTIVE_STYLES',
    'CANCEL_CHOOSE_STYLES',
    'CLEAR_ALL_STYLES',
    asyncAction('GET_STYLES'),
    'SET_STYLES',
    'SET_STYLES_FORCE'
);

export const setAllFields = payload => ({
    type: types.SET_ALL_FIELDS,
    payload
});
export const setMain = payload => ({
    type: types.SET_MAIN,
    payload
});
export const setBrand = payload => ({
    type: types.SET_BRAND,
    payload
});
export const setMaster = payload => ({
    type: types.SET_MASTER,
    payload
});
export const setPlace = payload => ({
    type: types.SET_PLACE,
    payload
});
export const setPlaceActive = payload => ({
    type: types.SET_PLACE_ACTIVE,
    payload
});
export const setZoom = payload => ({
    type: types.SET_ZOOM,
    payload
});
export const setDate = payload => ({
    type: types.SET_DATE,
    payload
});
export const setTime = payload => ({
    type: types.SET_TIME,
    payload
});
export const setRemoteInsurance = payload => ({
    type: types.SET_REMOTE_INSURANCE,
    payload
});
export const setReview = payload => ({
    type: types.SET_REVIEW,
    payload
});
export const saveFields = () => ({type: types.SAVE_FIELDS});
export const clearAllTabs = () => ({type: types.CLEAR_ALL_TABS});

export const setLocationSlug = payload => ({
    type: types.SET_LOCATION_SLUG,
    payload
});
export const setLocationId = payload => ({
    type: types.SET_LOCATION_ID,
    payload
});
export const setLocationName = payload => ({
    type: types.SET_LOCATION_NAME,
    payload
});
export const getLocationSlug = location => (dispatch, getState) => {
    const {
        searchFields: {
            selected: {
                place: {types: placeTypes = '', address_components: addressComponents = []}
            }
        }
    } = getState();
    const getTextPartIndex = components => {
        if (VALID_GEO_TYPES.includes(placeTypes)) {
            return (
                components.find(({types: componentTypes = []}) => componentTypes.includes(
                    placeTypes === 'aal1' ? 'administrative_area_level_1' : placeTypes
                    )
                ) || {}
            ).long_name;
        }
        return (
            components.find(({types: componentTypes = []}) => componentTypes.includes('locality')) || {}
        ).long_name;
    };
    const text = location || getTextPartIndex(addressComponents);

    return ajax({
        url: `${URL_GET_LOCATION_SLUG}?name=${text}`,
        cb: ({data: {slug, id, label}}) => {
            dispatch(setLocationSlug(slug));
            dispatch(setLocationId(id));
            dispatch(setLocationName(label));

            return slug;
        },
        err: error => console.log('Error ', error)
    });
};

export const getLocationByIp = () => (dispatch) => {

    dispatch({type: types.GET_LOCATION_BY_IP_REQUEST});
    return ajax({
        url: URL_GET_LOCATION_BY_IP,
        cb: ({data}) => {
            dispatch({
                type: types.GET_LOCATION_BY_IP_SUCCESS,
                payload: data
            });
            return data;
        }
    });
};

export const clearAllTerms = () => ({type: types.CLEAR_ALL_TERMS});
export const getTermsRequest = (
    tabId,
    language,
    value,
    servicesAmount
) => {
    const str = querystring.encode({
        limit_service: servicesAmount || 10,
        limit_problem: 10,
        limit_station: 20,
        limit_symptom: 10,
        tab_id: tabId
    });
    const url = `${URL_SEARCH_TERMS}?${str}${value ? `&text=${value}` : ''}`;

    return new Promise(((resolve, reject) => {
        ajax({
            url,
            cb: res => {
                resolve(res);
            },
            err: error => {
                reject(error);
            },
            language: language === 'uk' ? 'UA' : language.toUpperCase()
        })
    }));
};

export const clearCarModels = () => ({type: types.CLEAR_CAR_MODELS});
export const getModelsRequest = (value, language, additionalCallback = () => null) => {
    const url = `${URL_SEARCH_BRANDS_TERMS}${!isEmpty(value) ? '?text=' + value : ''}`;

    return ajax({
        url,
        cb: res => {
            additionalCallback(res);
            return res.data;
        },
        language: language.toUpperCase() === 'UK' ? 'UA' : language.toUpperCase()
    });
};

export const clearBrand = () => ({type: types.CLEAR_BRAND});

// Actions for Hallway
export const setQuadrature = payload => ({
    type: types.SET_QUADRATURE,
    payload
});
export const setPrice = payload => ({
    type: types.SET_PRICE,
    payload
});
export const addStyles = payload => ({
    type: types.ADD_STYLES,
    payload
});
export const removeStyles = payload => ({
    type: types.REMOVE_STYLES,
    payload
});
export const setActiveStyles = () => ({type: types.SET_ACTIVE_STYLES});
export const cancelChooseStyles = () => ({type: types.CANCEL_CHOOSE_STYLES});
export const clearAllStyles = () => ({type: types.CLEAR_ALL_STYLES});
export const setStyles = payload => ({
    type: types.SET_STYLES,
    payload
});
export const setStylesForce = payload => ({
    type: types.SET_STYLES_FORCE,
    payload
});
export const getStyles = () => (dispatch, getState) => {
    const {
        locate: {language}
    } = getState();

    return ajax({
        url: URL_GET_STYLES,
        cb: res => {
            dispatch(setStyles(res.data));
            return res.data;
        },
        language
    });
};
