import {asyncAction, createTypes} from 'redux-action-types';
import {ajax} from '../../api/ajax';
import {URL_COUNTRY_SETTINGS} from '../../api/routes';

export const types = createTypes('Country-info.', asyncAction('GET_COUNTRY_INFO'));

export const getCountryInfo = payload => (dispatch, getState) => {
  const {
    locate: {language}
  } = getState();
  dispatch({type: types.GET_COUNTRY_INFO_REQUEST});

  return ajax({
    url: URL_COUNTRY_SETTINGS(payload.toUpperCase()),
    cb: res => dispatch({
      type: types.GET_COUNTRY_INFO_SUCCESS,
      payload: {
        info: res.data,
        meta: res.meta
      }
    }),
    err: err => dispatch({type: types.GET_COUNTRY_INFO_FAIL, payload: err}),
    language: language === 'uk' ? 'UA' : language.toUpperCase()
  });
};
