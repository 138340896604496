import {asyncAction, createTypes} from 'redux-action-types';
import {ajax} from '../../api/ajax';
import * as routes from '../../api/routes';

export const types = createTypes(
    'Auth.',
    asyncAction('LOGIN'),
    asyncAction('REGISTER'),
    asyncAction('PASSWORD_FORGOT'),
    asyncAction('FACEBOOK'),
    asyncAction('GOOGLE'),
    asyncAction('LOGOUT'),
    'SET_USER',
    'SET_HELP_LINKS'
);

export const login = payload => dispatch => createAjax('LOGIN', payload, dispatch);
export const register = payload => dispatch => createAjax('REGISTER', payload, dispatch);
export const passwordForgot = payload => dispatch => createAjax('PASSWORD_FORGOT', payload, dispatch);
export const authFacebook = payload => dispatch => createAjax('FACEBOOK', payload, dispatch);
export const authGoogle = payload => dispatch => createAjax('GOOGLE', payload, dispatch);
export const logout = () => dispatch => onLogout(dispatch);
export const setUser = payload => ({type: types.SET_USER, payload});
export const setHelpLinks = payload => ({type: types.SET_HELP_LINKS, payload});

const createAjax = (name, payload, dispatch) => {
  const {data, cb, err} = payload;

  dispatch({type: types[`${name}_REQUEST`]});

  ajax({
    url: routes[`URL_AUTH_${name}`],
    method: 'POST',
    data,
    cb: res => {
      if (['LOGIN', 'FACEBOOK', 'GOOGLE'].includes(name)) {

        if (res.data.language) {
          document.cookie = `user-language=${res.data.language}`;
        }

        if (res.data.country_code) {
          document.cookie = `user-country=${res.data.country_code.toLowerCase()}`;
        }
      }

      if (name === 'LOGIN') {
        res.meta && localStorage.setItem('helpLinks', JSON.stringify(res.meta.help_links));
      }

      if (name === 'GOOGLE' || name === 'FACEBOOK') {
        dispatch(setUser(res.data));
      }

      dispatch({
        type: types[`${name}_SUCCESS`],
        payload: res
      });
      cb && cb(res);
    },
    err: error => {
      dispatch({
        type: types[`${name}_FAIL`],
        payload: error
      });
      err && err(error);
    }
  });
};

const onLogout = dispatch => {
  dispatch({type: types.LOGOUT_REQUEST});

  localStorage.removeItem('helpLinks');

  ajax({
    url: routes.URL_AUTH_LOGOUT,
    method: 'DELETE',
    cb: () => dispatch({type: types.LOGOUT_SUCCESS}),
    err: error => dispatch({type: types.LOGOUT_FAIL, payload: error})
  });
};
