import {asyncAction, createTypes} from 'redux-action-types';
import sortBy from 'lodash/sortBy';

import {ajax} from '../../api/ajax';
import {URL_COUNTRY_SETTINGS_HOME} from '../../api/routes';

export const types = createTypes('Home-info.', asyncAction('GET_HOME_INFO'));

export const getHomeInfo = () => (dispatch, getState) => {
  const {
    locate: {countryCode, language}
  } = getState();

  dispatch({type: types.GET_HOME_INFO_REQUEST});

  return ajax({
    url: URL_COUNTRY_SETTINGS_HOME(countryCode),
    cb: res => dispatch({
      type: types.GET_HOME_INFO_SUCCESS,
      payload: {
        info: sortBy(res.data, ['priority']),
        meta: res.meta.seo
      }
    }),
    err: err => dispatch({type: types.GET_HOME_INFO_FAIL, payload: err}),
    language: language === 'uk' ? 'UA' : language.toUpperCase()
  });
};
