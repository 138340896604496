import {asyncAction, createTypes} from 'redux-action-types';
import isNull from 'lodash/isNull';
import {ajax} from '../../api/ajax';
import {URL_SEARCH_FILTERS} from '../../api/routes';

export const types = createTypes(
    'SearchFilters.',
    asyncAction('GET_FILTERS'),
    'SET_ALL_FILTERS',

    'TOGGLE_SIZE',
    'SET_TOGGLE_SIZE',

    'TOGGLE_TYPE',
    'TOGGLE_BENEFITS',
    'TOGGLE_BODY',
    'TOGGLE_NETWORK',

    'SET_MAX_RADIUS',
    'SET_RADIUS',
    'SET_RADIUS_INPUT',
    'MAKE_RADIUS_ACTIVE',
    'ENABLE_RADIUS',
    'DISABLE_RADIUS',

    'SET_COST',
    'SET_COST_INPUT',
    'SET_SORT',
    'SET_BODY',
    'SET_STATION_TYPE',

    'APPLY_FILTERS',
    'RESET_FILTERS',

    'SET_BENEFITS',
    'RESET_BENEFITS',
    'ACCEPT_BENEFITS',
    'SAVE_FILTERS'
);

export const saveFilters = () => ({type: types.SAVE_FILTERS});

export const getFilters = () => (dispatch, getState) => {
  const {
    searchFilters: {sort},
    locate: {language}
  } = getState();

  dispatch({type: types.GET_FILTERS_REQUEST});

  return ajax({
    url: URL_SEARCH_FILTERS,
    cb: res => {
      isNull(sort) && dispatch({type: types.SET_SORT, payload: res.data.sort.params.default});
      dispatch({type: types.GET_FILTERS_SUCCESS, payload: res.data});
    },
    err: error => dispatch({type: types.GET_FILTERS_FAIL, payload: error}),
    language
  });
};

export const toggleSize = () => ({type: types.TOGGLE_SIZE});
export const setToggleSize = payload => ({
  type: types.SET_TOGGLE_SIZE,
  payload
});

export const setAllFilters = payload => ({type: types.SET_ALL_FILTERS, payload});

export const toggleType = payload => ({type: types.TOGGLE_TYPE, payload});
export const toggleBenefits = payload => ({type: types.TOGGLE_BENEFITS, payload});
export const toggleBody = payload => ({type: types.TOGGLE_BODY, payload});
export const toggleNetwork = payload => ({type: types.TOGGLE_NETWORK, payload});

export const setMaxRadius = payload => ({
  type: types.SET_MAX_RADIUS,
  payload
});
export const setRadius = payload => ({
  type: types.SET_RADIUS,
  payload
});
export const setRadiusInput = payload => ({type: types.SET_RADIUS_INPUT, payload});
export const makeRadiusActive = () => ({type: types.MAKE_RADIUS_ACTIVE});
export const enableRadius = () => ({type: types.ENABLE_RADIUS});
export const disableRadius = () => ({type: types.DISABLE_RADIUS});

export const setCost = payload => ({type: types.SET_COST, payload});
export const setCostInput = payload => ({type: types.SET_COST_INPUT, payload});
export const setSort = payload => ({type: types.SET_SORT, payload});
export const setBody = payload => ({type: types.SET_BODY, payload});
export const setStationType = payload => ({type: types.SET_STATION_TYPE, payload});

export const resetFilters = () => (dispatch, getState) => {
  const {search_distance: searchDistance} = getState().countryInfo.info;
  return dispatch({
    type: types.RESET_FILTERS,
    payload: searchDistance
  });
};

export const setBenefits = payload => ({type: types.SET_BENEFITS, payload});
export const resetBenefits = () => ({type: types.RESET_BENEFITS});
export const acceptBenefits = () => ({type: types.ACCEPT_BENEFITS});
