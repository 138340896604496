const API_KEY_GOOGLE_MAPS = process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY;

export const VALID_GEO_TYPES = ['neighborhood', 'locality', 'country', 'aal1'];

export const GOOGLE_MAP_URL = `https://maps.googleapis.com/maps/api/js?key=${API_KEY_GOOGLE_MAPS}&v=3.exp&libraries=geometry,places,slug`;

export const WIDTH_LARGE = 1600;
export const WIDTH_DESKTOP = 1200;
export const WIDTH_LAPTOP = 992;
export const WIDTH_TABLET = 768;
export const WIDTH_PHABLET = 576;

