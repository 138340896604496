import {createTypes} from 'redux-action-types';

export const types = createTypes(
    'Common.',
    'SET_FULL_MOBILE_MAP',
    'REMOVE_FULL_MOBILE_MAP',

    'SET_VALUE_OF_SEARCH_PAGE_HEADER',
    'SET_VALUE_OF_SEARCH_PAGE_RESULTS',

    'SET_INPUT_MAIN_PROPS',

    'SET_INPUT_STYLES_PROPS',

    'SET_POPULAR_SERVICES',

    'TOGGLE_SEARCH_OPTIONS',

    'SET_USER_CURRENT_POSITION',

    'CHANGE_ERROR_PAGE_STATE',

    'SET_IS_MOUNT',
    'ADD_STYLES',
    'REMOVE_STYLES',
    'CLEAR_ALL_STYLES',
    'TOGGLE_SPINNER'
);

export const toggleSpinner = () => ({type: types.TOGGLE_SPINNER});

export const setFullMobileMap = () => ({type: types.SET_FULL_MOBILE_MAP});
export const removeFullMobileMap = () => ({type: types.REMOVE_FULL_MOBILE_MAP});

export const setValuesOfSearchPageHeader = payload => ({
  type: types.SET_VALUE_OF_SEARCH_PAGE_HEADER,
  payload
});
export const setValuesOfSearchPageResults = payload => ({
  type: types.SET_VALUE_OF_SEARCH_PAGE_RESULTS,
  payload
});
export const setPopularServices = payload => ({type: types.SET_POPULAR_SERVICES, payload});

export const setInputMainProps = payload => ({type: types.SET_INPUT_MAIN_PROPS, payload});
export const setInputStylesProps = payload => ({type: types.SET_INPUT_STYLES_PROPS, payload});
export const toggleSearchOptions = () => ({type: types.TOGGLE_SEARCH_OPTIONS});

export const setUserCurrentPosition = payload => ({
  type: types.SET_USER_CURRENT_POSITION,
  payload
});

export const changeErrorPageState = payload => ({
  type: types.CHANGE_ERROR_PAGE_STATE,
  payload
});

export const setIsMount = () => ({type: types.SET_IS_MOUNT});

export const addStyles = payload => ({
  type: types.ADD_STYLES,
  payload
});

export const removeStyles = payload => ({
  type: types.REMOVE_STYLES,
  payload
});

export const clearAllStyles = () => ({
  type: types.CLEAR_ALL_STYLES
});
