import {asyncAction, createTypes} from 'redux-action-types';
import querystring from 'querystring';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import uniq from 'lodash/uniq';
import {ajax} from '../../api/ajax';
import {URL_SEARCH, URL_SEARCH_PARSE_LINK} from '../../api/routes';
import {getSearchSeo} from '../seo/actions';
import {disableRadius, enableRadius} from '../searchFilters/actions';
import {saveFields, setPlace} from '../searchFields/actions';

let timeout;
let indexRequest = 0;
export const stationPageSize = 25;

export const types = createTypes(
    'Search.',
    asyncAction('GET_SEARCH'),
    'UNHOVER_SEARCH_ITEM',
    'HOVER_SEARCH_ITEM',
    'SELECT_SEARCH_ITEM',
    'DESELECT_SEARCH_ITEM',
    'ENABLE_AREA_SEARCH',
    'DISABLE_AREA_SEARCH',
    'ENABLE_ADDRESS_SEARCH',
    'DISABLE_ADDRESS_SEARCH',
    'SET_COORDINATES',
    'UPDATE_COORDINATES',
    'SET_LEFT_BOUND',
    'SET_PAGE_WIDTH',
    'SET_RESULTS_OBTAINING',
    'CHANGE_URL_CHANGING_STATE',
    'CHANGE_FORCE_CENTER_MAP_STATE',
    'CHANGE_ZOOMING_MAP_STATE',
    'SORT_RESULTS_BY',
    'SET_SEARCHING_TYPE',
    'CHANGE_FORCE_ENABLE_ADDRESS_SEARCH_STATE',

    // Hallway types
    asyncAction('GET_SEARCH_HALLWAY'),
    'SET_DESIGNERS_CARDS',
    'SET_NEXT_DESIGNERS_CARDS',
    'SET_CITY',

    'SEARCH_HALLWAY_REQUEST',
    'SEARCH_HALLWAY_REQUEST_SUCCESS',
    'SEARCH_HALLWAY_REQUEST_FAILED',
    'SET_SEARCH_META',
    'SET_PARSED_URL_PARAMS',
    'SET_SEARCHED_DATA',
    'SET_BROWSER_POSITION',
    'SET_CAR_POSITION'
);

export const setCarPosition = (position = null) => dispatch => dispatch({
  type: types.SET_CAR_POSITION,
  payload: {
    position
  }
});


export const setBrowserPosition = (position = null) => dispatch => dispatch({
  type: types.SET_BROWSER_POSITION,
  payload: {
    position
  }
});

export const searchByParams = (params) => (dispatch, getState) => {

  const {locate: {language}} = getState();

  const str = querystring.encode(params);

  dispatch(getSearchSeo({
    ...params
  }));

  dispatch({
    type: types.GET_SEARCH_REQUEST
  });
  return ajax({
    url: `${URL_SEARCH}?${str}`,
    method: 'GET',
    cb: res => {
      dispatch(setResultsObtaining(res.meta.attributes.stations_count));
      const {searchFields: {selected: {place}}} = getState();
      const location = get(res, 'meta.attributes.location');

      isEmpty(place) && location && dispatch(setPlace({
        text: location,
        lat: get(res, 'meta.attributes.lat', NaN),
        lng: get(res, 'meta.attributes.lng', NaN)
      }));
      return dispatch({
        type: types.GET_SEARCH_SUCCESS,
        payload: res
      });
    },
    err: error => dispatch({
      type: types.GET_SEARCH_FAIL,
      payload: error
    }),
    language
  });
};

export const setParsedUrlParams = (params = [], extra = []) => ({
  type: types.SET_PARSED_URL_PARAMS,
  payload: {
    params,
    extra
  }
});

export const hoverSearchItem = payload => ({
  type: types.HOVER_SEARCH_ITEM,
  payload
});
export const unhoverSearchItem = () => ({type: types.UNHOVER_SEARCH_ITEM});
export const selectSearchItem = payload => ({
  type: types.SELECT_SEARCH_ITEM,
  payload
});
export const deselectSearchItem = () => ({type: types.DESELECT_SEARCH_ITEM});
export const enableAreaSearch = () => ({type: types.ENABLE_AREA_SEARCH});
export const disableAreaSearch = () => ({type: types.DISABLE_AREA_SEARCH});
export const changeForceEnableAddressSearchState = payload => ({
  type: types.CHANGE_FORCE_ENABLE_ADDRESS_SEARCH_STATE,
  payload
});
export const setCoordinates = payload => ({
  type: types.SET_COORDINATES,
  payload
});
export const setLeftBound = payload => ({
  type: types.SET_LEFT_BOUND,
  payload
});
export const setPageWidth = payload => ({
  type: types.SET_PAGE_WIDTH,
  payload
});
export const setResultsObtaining = payload => ({
  type: types.SET_RESULTS_OBTAINING,
  payload
});
export const changeUrlChangingState = payload => ({
  type: types.CHANGE_URL_CHANGING_STATE,
  payload
});
export const changeForceCenterMapState = payload => ({
  type: types.CHANGE_FORCE_CENTER_MAP_STATE,
  payload
});
export const changeZoomingMapState = payload => ({
  type: types.CHANGE_ZOOMING_MAP_STATE,
  payload
});
export const sortResultsBy = payload => ({
  type: types.SORT_RESULTS_BY,
  payload
});
export const setSearchingType = payload => ({
  type: types.SET_SEARCHING_TYPE,
  payload
});

export const getSearch = (searchingType, forceRequest) => (dispatch, getState) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }

  dispatch(setSearchingType(searchingType));

  const {
    searchFilters: {
      sort,
      filters: {filters},
      active: {type, benefits, body, network, radius, cost},
      radiusIsEnable
    },
    searchFields: {active: activeFields, selected: selectedFields}
  } = getState();
  const validAddress = selectedFields.place.search_by_map || selectedFields.place.types;
  const {main, brand, place, date, time} = validAddress ? selectedFields : activeFields;

  if (
      isEqual(selectedFields, omit(activeFields, ['locationSlug', 'locationId']))
      && !place.search_by_map
      && !forceRequest
      && searchingType !== 'searchByRadius'
  ) {
    dispatch(changeForceCenterMapState(true));
    return;
  }

  indexRequest++;

  const indexReq = indexRequest;
  const newPlace = {
    ...omit(
        place,
        ['bounds', 'text', 'address_components', 'types'].concat(
            place.search_by_map ? ['lat', 'lng'] : ['ne_lat', 'ne_lng', 'sw_lat', 'sw_lng']
        )
    )
  };

  const {searchFields: {active: {locationId}}} = getState();
  const service_ids = main
      .filter(i => i.type === '1')
      .map(i => i.value && i.value.split('--')[0])
      .join();
  const problem_ids = main
      .filter(i => i.type === '2')
      .map(i => i.value && i.value.split('--')[0])
      .join();

  const str = querystring.encode(
      merge(
          {
            ...newPlace
            // page_number,
            // page_size
          },
          service_ids ? {service_ids} : {},
          problem_ids ? {problem_ids} : {},
          date ? {date} : {},
          time ? {time} : {},
          cost.length ? {hourly_rate: cost.join()} : {},
          type.length
              ? {
                marker_ids: type
                    .map(
                        item => filters.find(({id}) => id === 'markers')
                            .list
                            .find(({slug}) => slug === item)
                            .id
                    )
                    .join()
              }
              : {},
          place.types && !place.search_by_map ? {geo_types: place.types} : {},
          benefits.length ? {additional_service_ids: benefits.join()} : {},
          network ? {networked: network} : {},
          sort ? {sort_by: sort} : {},
          body.length ? {car_body_type_ids: body} : {},
          searchingType === 'searchByRadius' && radius && radiusIsEnable ? {distance: radius} : {},
          !isEmpty(brand) && brand.id ? {brand_id: brand.id} : {},
          locationId ? {city_id: locationId} : {}
      )
  );

  dispatch({type: types.GET_SEARCH_REQUEST});

  dispatch(getSearchSeo({
    city_id: locationId,
    service_ids: service_ids,
    problem_ids: problem_ids,
    brand_id: brand && brand.id,
    ...newPlace
  }));

  timeout = setTimeout(() => {
    ajax({
      url: URL_SEARCH + '?' + str,
      cb: res => {
        if (indexReq === indexRequest) {
          dispatch({
            type: types.GET_SEARCH_SUCCESS,
            payload: res
          });
          dispatch(setResultsObtaining(res.meta.attributes.stations_count));

          const distance = +res.meta.attributes.distance;

          if (distance) {
            dispatch(
                place.types === 'neighborhood' || place.types === 'country' || place.search_by_map
                    ? disableRadius()
                    : enableRadius()
            );
          }

          if (validAddress) {
            dispatch(saveFields());
          }

          if (place.search_by_map) {
            dispatch(changeForceEnableAddressSearchState(true));
          } else {
            dispatch(changeZoomingMapState(true));
            dispatch(disableAddressSearch());
          }

          if (searchingType === 'searchByRadius') {
            dispatch(changeForceCenterMapState(true));
          }
        }
      },
      err: error => dispatch({
        type: types.GET_SEARCH_FAIL,
        payload: error
      })
    });
  }, 1000);
};

export const parseLink = (forParse, language) => {
  const str = querystring.encode({...omitBy(forParse, isEmpty)});

  return ajax({
    url: URL_SEARCH_PARSE_LINK + '?' + str,
    method: 'GET',
    cb: res => {
      return res;
    },
    err: error => {
      console.log(error);
    },
    language: language.toUpperCase() === 'UK' ? 'UA' : language.toUpperCase()
  });
};

function prepareSearchStr(getState) {
  const {
    searchFields: {
      selected: {
        main,
        brand,
        place: {lat, lng},
        quadrature,
        price,
        styles
      },
      active: {
        locationId
      }
    },
    locate: {language}
  } = getState();
  const serviceIds = main
      .filter(i => i.type === '1')
      .map(i => i.value && i.value.split('--')[0])
      .join();
  const styleIds = uniq(styles.filter(s => s)
      .map(({styleId}) => styleId))
      .join();
  return querystring.encode(
      merge(
          serviceIds ? {service_ids: serviceIds} : {},
          !isEmpty(brand) && brand.id ? {brand_id: brand.id} : {},
          quadrature ? {quadrature} : {},
          lat ? {lat} : {},
          lng ? {lng} : {},
          styles.length ? {style_ids: styleIds} : {},
          price ? {price_range: price} : {},
          locationId ? {city_id: locationId} : {}
      )
  );
}

// Hallway actions
export const getSearchHallway = () => (dispatch, getState) => {

  const str = prepareSearchStr(getState);
  const {locate: {language}} = getState();

  dispatch(searchHallwayRequest());

  return ajax({
    url: URL_SEARCH + '?' + str + `&page_number=1&page_size=${stationPageSize}`,
    method: 'GET',
    cb: res => {
      dispatch(setDesignersCards(res.data));
      dispatch(setSearchMeta(res.meta));
      dispatch(saveFields());
      dispatch(searchHallwayRequestSuccess());
    },
    err: error => {
      dispatch(searchHallwayRequestFailed());
      console.log(error);
    },
    language
  });
};

export const getNextSearchHallway = () => (dispatch, getState) => {

  const str = prepareSearchStr(getState);
  const {locate: {language}, search: {meta: {pagination: {next_page: nextPage}}}} = getState();

  return ajax({
    url: URL_SEARCH + '?' + str + `&page_number=${nextPage}&page_size=${stationPageSize}`,
    method: 'GET',
    cb: res => {
      dispatch({
        type: types.SET_NEXT_DESIGNERS_CARDS,
        payload: res
      });
    },
    err: error => {
      dispatch(searchHallwayRequestFailed());
      console.log(error);
    },
    language
  });
};

export const setCity = payload => {
  return {
    type: types.SET_CITY,
    payload
  };
};

export const searchHallwayRequest = () => ({
  type: types.SEARCH_HALLWAY_REQUEST
});

export const searchHallwayRequestSuccess = () => ({
  type: types.SEARCH_HALLWAY_REQUEST_SUCCESS
});

export const searchHallwayRequestFailed = () => ({
  type: types.SEARCH_HALLWAY_REQUEST_FAILED
});

export const setDesignersCards = payload => ({
  type: types.SET_DESIGNERS_CARDS,
  payload
});

export const setSearchMeta = payload => ({
  type: types.SET_SEARCH_META,
  payload
});
