import {asyncAction, createTypes} from 'redux-action-types';
import querystring from 'querystring';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import {ajax} from '../../api/ajax';
import {URL_GET_SEO_BUSINESS_PAGE, URL_GET_SEO_HOME, URL_GET_SEO_SEARCH} from '../../api/routes';

export const types = createTypes(
    'Seo.',
    asyncAction('GET_SEARCH_SEO'),
    asyncAction('GET_HOME_SEO'),
    asyncAction('GET_BUSINESS_PAGE_SEO'),
    'SET_SEO',
    'SET_META'
);

export const setSeo = payload => ({type: types.SET_SEO, payload});

export const getHomeSeo = () => (dispatch, getState) => {
  const {
    locate: {language, countryCode}
  } = getState();

  return ajax({
    url: URL_GET_SEO_HOME(countryCode),
    cb: res => {
      dispatch(setSeo(res));
    },
    err: error => console.log('Error ', error),
    language
  });
};

export const getBusinessPageSeo = (id) => (dispatch, getState) => {
  const {
    locate: {language, countryCode}
  } = getState();

  return ajax({
    url: URL_GET_SEO_BUSINESS_PAGE(id, countryCode),
    cb: res => {
      dispatch(setSeo(res));
    },
    err: error => console.log('Error ', error),
    language
  });
};

export const setMeta = payload => (dispatch) => {
  return dispatch({
    type: types.SET_META,
    payload
  });
};


export const getSearchSeo = options => (dispatch, getState) => {

  const str = querystring.encode(
      merge(
          omit(options, 'search_by_map'),
          'search_by_map' in options ? {search_by_map: true} : {}
      )
  );
  const {
    locate: {language, countryCode}
  } = getState();

  return ajax({
    url: `${URL_GET_SEO_SEARCH(countryCode)}&${str}`,
    cb: res => {
      dispatch(setSeo(res));
    },
    err: error => console.log('Error ', error),
    language
  });
};
